import HImage from "@/components/ui/HImage";
import HSelect from "@/components/ui/HSelect";
import { useLanguage } from "@/context/language";

import vn from "../../../assets/logo/vn.png";
import en from "../../../assets/logo/en.svg";

const option: any = [
  {
    label: "Tiếng Việt",
    value: "vi",
    icon: <HImage src={vn} width={24} height={20} alt="vndsadasdas" />,
  },
  {
    label: "English",
    value: "en",
    icon: <HImage src={en} width={24} height={20} alt="endasdas" />,
  },
];

const HSelectLang = () => {
  const { changeLanguage, locale } = useLanguage();
  return (
    <HSelect
      options={option}
      value={option.find((i: any) => i.value === locale).label}
      valueField={option.find((i: any) => i.value === locale).href}
      labelField={option.find((i: any) => i.value === locale).label}
      icon={option.find((i: any) => i.value === locale).icon}
      rawValue={true}
      onSelected={(e: any) => changeLanguage(e.value)}
    />
  );
};

export default HSelectLang;
