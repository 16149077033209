import HImage from "@/components/ui/HImage";
import { useLanguage } from "@/context/language";
import clsx from "clsx";
import Link from "next/link";
import logo from "../../../assets/logo/logoWhite.png";
import { HAbouts, HContact } from "./data";
import styles from "./styles.module.scss";

// interface IFooterProps {
//   show?: boolean;
// }

const HFooter = () => {
  const { messages } = useLanguage();
  return (
    <div className={clsx(styles.HFooter)}>
      <div className="grid md:container lg:grid-cols-3 md:grid-cols-1">
        <div className="">
          <HImage
            src={logo}
            width={210}
            height={32}
            layout="fixed"
            alt="ICANID"
          />
          <div className="text-xs text-[#E4E4E4] font-normal py-2">
            Copyright © 2020
          </div>
        </div>
        <div className="grid col-span-2 gap-2 md:grid-cols-4">
          <div className="col-span-1 text-white">
            <div className="py-3 text-sm font-bold text-white uppercase">
              {messages.about}
            </div>
            <ul className="text-sm font-medium ">
              {HAbouts.map((i, index) => (
                <li key={index} className="py-1">
                  <Link href={i.link}>{i.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-3 text-white">
            <div className="py-3 text-sm font-bold text-white uppercase">
              {messages.contact}
            </div>
            <ul className="text-sm font-medium ">
              <li>{HContact.address}</li>
              <li className="py-1">
                <span>Hotline: </span>
                <span className="text-[#FF961B]">{HContact.hotline}</span>
              </li>
              <li className="py-1">
                <span>Email: </span>
                <span className="text-[#FF961B]">{HContact.email}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HFooter;
