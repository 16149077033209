import Image from "next/image";
import Cookies from "js-cookie";

import logo from "../../../assets/logo/logoGala.png";
import s from "./styles.module.scss";

import ICmenu from "../../../assets/logo/ic-menu.png";
import { useState } from "react";
import clsx from "clsx";
import { AiOutlineCloseSquare } from "react-icons/ai";

import { FaUserAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import HModalWarning from "../HModalWarning";
import { useRouter } from "next/router";
import { useAuth } from "@/context/auth";
import HImage from "@/components/ui/HImage";
import Link from "next/link";
import { useLanguage } from "@/context/language";
import HButton from "@/components/ui/HButton";
import HSelect from "@/components/ui/HSelect";
import HSelectLang from "../HSelectLang";
import { useUser } from "@icanid/icanid-sdk-nextjs/client";

type IUserProp = {
  user?: any;
};

const UserDropDown = ({ user }: IUserProp) => {
  const { messages } = useLanguage();
  //const { user, error, isLoading } = useUser();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleLogout = async () => {
    setLoading(true);
    window.location.href = "/api/auth/logout";
    setLoading(false);
  };

  if (!user) {
    return <></>;
  }

  if (confirm) {
    return (
      <HModalWarning
        message={messages.wantoLogout}
        onClick={handleLogout}
        onClose={() => setConfirm(false)}
        loading={loading}
        success={success}
        showButton={true}
      />
    );
  }

  return (
    <div className="relative inline-block text-left dropdown">
      <span className="rounded-md shadow-sm">
        <button
          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {(user && user?.displayName) ||
              user?.email ||
              user?.phone ||
              user?.username}
          </span>
          <span className="mt-1 ml-2">
            {isOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </button>
      </span>
      {isOpen && (
        <div className="transition-all duration-300 origin-top-right transform scale-95 -translate-y-2 ">
          <div className="absolute right-0 w-48 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">
            <div className="py-1">
              <a
                className="flex justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-left text-gray-700 cursor-pointer hover:text-primary"
                role="menuitem"
                onClick={() => setConfirm(true)}
              >
                { messages.logout }
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const HNavbar = ({ user }: IUserProp) => {
  const { messages } = useLanguage();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const router = useRouter();
  //const { user } = useUser();

  const OnToggle = () => {
    setOpen(!open);
  };
  const handleLogout = async () => {
    setLoading(true);
    // const params = new URLSearchParams({
    //   state: uuid.v4(),
    //   id_token_hint: Cookies.get('ICAN_ID.id_token'),
    //   post_logout_redirect_uri: process.env.IS_DEV
    //     ? 'http://localhost:3000'
    //     : process.env.NEXT_PUBLIC_ICAN_ID_URL || '',
    // });
    try {
      localStorage.removeItem("token");
      setSuccess(true);
      const url = `${
        process.env.NEXT_PUBLIC_ICAN_ID_URL || ""
      }/api/oauth/login`;
      Cookies.remove("ICAN_ID.id_token");
      Cookies.remove("ICAN_ID.access_token");
      setConfirm(false);
      //window.location.href = url;
      router.push(url, undefined, { shallow: true });
      //await oauth.revokeToken(Cookies.get('ICAN_ID.access_token'));
    } catch {
      setSuccess(false);
    }

    setLoading(false);
  };
  return (
    <div className={s.navbar}>
      <div className="flex items-center justify-between">
        <Link href="/">
          <HImage
            alt="ICANID"
            src={logo}
            width={210}
            height={32}
            layout="fixed"
          />
        </Link>
        <div className="flex items-center justify-end mobile:hidden">
          <div className="ml-2">
            <HSelectLang />
          </div>
          <div>
            <UserDropDown user={user} />
          </div>

          {/* <HImage src={icMenu} width={32} height={32} layout="fixed" /> */}
        </div>
        <div className="hidden ml-4 cursor-pointer hamburger mobile:block">
          <HImage
            src={ICmenu}
            alt={"icon sidebar"}
            onClick={OnToggle}
            width={32}
            height={32}
            layout="fixed"
          />
        </div>
        {open && (
          <div className={clsx(s.navbarMenu, { [s.open]: open })}>
            <div className="absolute right-4">
              <AiOutlineCloseSquare
                className=" text-[32px] text-primary-600 mr-0 cursor-pointer"
                onClick={() => setOpen(!open)}
              />
            </div>
            <div className={s.navbarItems}>
              <div className="ml-2">
                <HSelectLang />
              </div>
              {user && (
                <>
                  <div className="flex items-center justify-start mt-5">
                    <div className={s.wrapImg}>
                      {user?.avatar ? (
                        <Image
                          src={user?.avatar}
                          alt=""
                          className={s.imageP}
                          unoptimized
                          width={40}
                          height={40}
                        />
                      ) : (
                        <FaUserAlt className="text-[15px] text-[#7E7E7E]" />
                      )}
                    </div>
                    <div className="ml-2 text-[#404040] font-medium text-[16px]">
                      {(user && user?.displayName) ||
                        user?.email ||
                        user?.phone ||
                        user?.username}
                    </div>
                  </div>

                  <div className="py-1">
                    <a
                      className="flex justify-between w-full mt-4 text-[#404040] !font-medium !text-[16px] cursor-pointer hover:text-primary"
                      role="menuitem"
                      onClick={() => setConfirm(true)}
                    >
                      { messages.logout }
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {open && <div className={s.overlay}></div>}
        {confirm && (
          <HModalWarning
            message={messages.wantoLogout}
            onClick={handleLogout}
            onClose={() => setConfirm(false)}
            loading={loading}
            success={success}
            showButton={true}
          />
        )}
      </div>
    </div>
  );
};

export default HNavbar;
