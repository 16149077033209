export const HAbouts = [
  {
    name: 'Về Chúng Tôi',
    link: '/',
  },
  {
    name: 'Thương Hiệu',
    link: '/',
  },
  {
    name: 'Tin Tức',
    link: '/',
  },
];

export const HContact = {
  address:
    '59C Xa Lộ Hà Nội, Phường Thảo Điền, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam',
  hotline: ' (028) 7300 3267',
  email: 'education@galaxy.com.vn',
};
