import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BsFillCheckCircleFill } from "react-icons/bs";

import { IOptionItemProps, ISelectProps } from "./index.type";
import styles from "./styles.module.scss";
import { _get } from "@/plugins/lodash/_get";
import { useOuterClick } from "@/plugins/event";

const OptionItem = ({
  value,
  label,
  onSelectItem,
  isSelected = false,
  className,
  icon,
}: IOptionItemProps) => {
  const handleOnClick = (e: any) => {
    e.stopPropagation();
    onSelectItem(value);
  };
  return (
    <div
      onClick={handleOnClick}
      className={clsx(styles.optionItem, {
        [styles.selected]: isSelected,
      })}
    >
      <div className="relative flex items-center w-full p-2 pl-2 border-transparent hover:bg-primary-200">
        <div className="flex items-center justify-between w-full">
          <div className={clsx("mx-2 leading-6 text-[16px]", className)}>
            {label}
          </div>
          <div>{icon}</div>
        </div>
      </div>
    </div>
  );
};

const HSelect = (props: ISelectProps) => {
  const [defaultValueField, defaultLabelField] =
    typeof props.options[0] === "object" ? ["id", "label"] : ["", ""];
  const {
    valueField = defaultValueField,
    labelField = defaultLabelField,
    placeholder = "Nhấn để chọn...",
    rawValue = true,
  } = props;
  const selectRef = useRef();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(props.value);

  const options = useMemo(() => {
    return props.options.map((op, idx: any) => {
      return {
        key: _get(op, valueField, idx),
        value: op,
        label: op.label,
        isSelected:
          _get(op, valueField) === _get(value, valueField) || op === value,
      };
    });
  }, [props.options, value, valueField]);

  const handleClickItem = (value: any) => {
    // @ts-ignore
    props.onSelected(getRawValue(value));
    setVisible(false);
  };

  const handleClickSelect = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    let val = props.value;
    if (!rawValue) {
      val = props.options.find((option) => option[valueField] === val);
    }

    setValue(val);
  }, [props.value, props.options, rawValue, valueField]);

  useOuterClick(selectRef, () => {
    setVisible(false);
    typeof props.onBlur === "function" && props.onBlur(value);
  });

  const getRawValue = (val: any) => {
    // let val = props.value;
    if (!rawValue) {
      val = _get(val, valueField);
    }
    return val;
  };
  return (
    <>
      <div
        // @ts-ignore
        ref={selectRef}
        className={clsx(styles.select, { [styles.open]: visible })}
        onClick={props.disabled ? undefined : handleClickSelect}
      >
        <div className="w-full">
          <div
            className={clsx(
              "flex bg-white border border-[#D0D0D0] rounded",
              props.className,
              props.disabled && styles.disabled
            )}
          >
            <div className={clsx(styles.textSelect)}>
              {props.icon}
              {placeholder && !value ? (
                <span className="mx-2 leading-6 text-gray-500">
                  {placeholder}
                </span>
              ) : (
                <span
                  className={clsx(
                    "mx-2 leading-6",
                    props.disabled && "text-gray-500"
                  )}
                >
                  {value && value[labelField] ? value[labelField] : value}
                </span>
              )}
            </div>
            <div className={styles.input}>
              <button className={styles.selectIcon}>
                {visible ? <FiChevronUp /> : <FiChevronDown />}
              </button>
            </div>
          </div>
        </div>
        <div className={styles.options}>
          <div className="flex flex-col w-full">
            {options.map((option) => {
              return (
                <div
                  key={option.key}
                  className="flex items-center justify-between"
                >
                  <OptionItem
                    value={option.value}
                    label={option.label}
                    isSelected={option.isSelected}
                    onSelectItem={handleClickItem}
                    className={clsx(
                      value === option.value.label &&
                        "text-primary  font-semibold"
                    )}
                    icon={
                      value === option.value.label && (
                        <BsFillCheckCircleFill className="text-primary" />
                      )
                    }
                  ></OptionItem>
                  {}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HSelect;
