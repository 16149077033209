import { useEffect } from "react";

export const useOuterClick = (ref: any, callback: () => void) => {
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (ref.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    callback();
  };
};
