import HButton from "@/components/ui/HButton";
import HImage from "@/components/ui/HImage";
import HModal from "@/components/ui/HModal";
import { useLanguage } from "@/context/language";
import React from "react";

import icError from "../../../assets/logo/error.png";

interface IModalErrorProp {
  onClose?: () => void;
  title?: string;
  message?: string;
  hiddenButton?: boolean;
}

const HModalError = (props: IModalErrorProp) => {
  const { messages } = useLanguage();
  return (
    <HModal visible={true} showCloseButton={false}>
      <div className="p-8 text-center glx:p-0">
        <div>
          <HImage
            src={icError}
            width={112}
            height={112}
            layout="intrinsic"
            alt="ICANID"
            className="mx-auto"
          />
        </div>
        <div className="text-primary font-bold text-[32px]">
          {props.title || messages.error}
        </div>
        <div className="text-[#484848] font-normal text-base py-2 mb-6">
          {props.message}
        </div>
        { !props.hiddenButton && 
        <HButton
          variant="primary"
          size="md"
          className="font-semibold rounded-full w-[144px] h-[44px]"
          onClick={props.onClose}
          rounded="round-full"
        >
          {messages.close}
        </HButton>
        }
      </div>
    </HModal>
  );
};

export default HModalError;
